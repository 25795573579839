import { useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, chatApi } from "@api";

export const useGetChatProject = ({ projectId }: { projectId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.CHAT_PROJECTS, projectId],
    queryFn: () => chatApi.getChatProject(projectId),
    enabled: !!projectId
  });
  return { chatProject: data?.data, isLoading };
};
