import styled from "@emotion/styled";
import { Button } from "antd";
import {
  createContext,
  Dispatch,
  lazy,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";

import { RepoModel } from "@types";
import { GraphInSvg, OverviewSvg, SysAssessmentSvg } from "@assets/svg";
import { ExplorationContent } from "@pages/exploration/ExplorationContent";

export const ExportBtn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary100}80;

  div {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 6px;
    }
  }
`;

const TopbarLayout = lazy(() => import("@layouts/TopbarLayout"));

const ProjectContext = createContext<{ project: RepoModel }>({} as { project: RepoModel });

export const useProject = () => {
  return useContext(ProjectContext);
};

const RouteContext = createContext<{
  recentRoutes: Record<string, string>;
  setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
}>(
  null as unknown as {
    recentRoutes: Record<string, string>;
    setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
  }
);

export const useExplorationRoute = () => {
  return useContext(RouteContext);
};

export const routeMap: Record<string, string> = {
  inventory: "Inventory",
  complexity: "Complexity",
  data: "Data inventory",
  graph: "Dependency Graph",
  entrypoints: "Entrypoints",
  reports: "Reports",
  overview: "Overview",
  "programs-reports": "Programs Reports"
};

export const ExplorationRouter = () => {
  const navigate = useNavigate();
  const { repoId, explorationCategory } = useParams();
  const [recentRoutes, setRecentRoutes] = useState<Record<string, string>>({});

  const mapRoute = useCallback((route: string) => routeMap[route] || "", []);

  const onChangeSegmented = useCallback((value: string) => {
    const key = value.split("/").at(-1) || value.split("/").at(-2) || "assets";
    const route = `/exploration/${repoId}/${value}`;
    const keyRoute = mapRoute(key);
    navigate(route);
    if (keyRoute)
      setRecentRoutes(prev => {
        const routes = { ...prev };
        routes[keyRoute] = `${window.location.pathname}${window.location.search}`;
        return routes;
      });
  }, []);

  useEffect(() => {
    const { pathname, search } = window.location;
    const route = search
      ? "reverse"
      : pathname.split("/").at(-1) || pathname.split("/").at(-2) || "inventory";

    const keyRoute = mapRoute(route);

    if (keyRoute)
      setRecentRoutes(prev => {
        const routes = { ...prev };

        routes[keyRoute] = `${pathname}${search}`;
        return routes;
      });
  }, []);

  return (
    <TopbarLayout
      key='topbar-layout'
      segmented={{
        options: [
          {
            label: "Overview",
            key: "overview",
            icon: <OverviewSvg />
          },
          {
            label: "Assessment",
            key: "assets",
            icon: <SysAssessmentSvg />,
            children: [
              {
                label: "Inventory",
                key: "inventory"
              },
              {
                label: "Complexity",
                key: "complexity"
              },
              {
                label: "Data Inventory",
                key: "data"
              }
            ]
          },
          {
            label: "Reverse Engineering",
            key: "reverse",
            icon: <GraphInSvg />,
            children: [
              {
                label: "Dependency Graph",
                key: "graph"
              },
              {
                label: "Entrypoints",
                key: "entrypoints"
              },
              {
                label: "Reports",
                key: "reports"
              },
              {
                label: "Programs Reports",
                key: "programs-reports"
              }
            ]
          }
        ],
        onChange: onChangeSegmented,
        defaultValue: "overview",
        value: explorationCategory
      }}
    >
      <RouteContext.Provider value={{ recentRoutes, setRecentRoutes }}>
        <ExplorationContent recentRoutes={recentRoutes} setRecentRoutes={setRecentRoutes} />
      </RouteContext.Provider>
    </TopbarLayout>
  );
};
