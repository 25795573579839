import { useMutation, useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, repositoryApi } from "@api";
import { DetailRepositoryInput, QueryParamsInput } from "@types";

export const useCreateRepository = () => {
  return useMutation({ mutationFn: repositoryApi.createRepository });
};

export const useDeleteRepository = () => {
  return useMutation({ mutationFn: repositoryApi.deleteRepository });
};

export const useUpdateRepository = () => {
  return useMutation({ mutationFn: repositoryApi.updateRepository });
};

export const useGetRepositoryProject = ({ projectId }: { projectId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.PROJECTS, projectId],
    queryFn: () => repositoryApi.getRepositoriesProject(projectId),
    enabled: !!projectId
  });
  return { repositoryProject: data?.data, isLoading };
};

export const useGetRepositories = (params: QueryParamsInput) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_ENPOINTS.REPOSITORIES, params],
    queryFn: () => repositoryApi.getListRepositories(params),
    enabled: true
  });
  return { repositories: data?.data?.repositories, isLoading, refetch };
};
export const useDetailRepositoriy = ({ repositoryId }: DetailRepositoryInput) => {
  const { data, isLoading, refetch } = useQuery({
    queryKey: [API_ENPOINTS.REPOSITORIES, repositoryId],
    queryFn: () => repositoryApi.getDetailRepository({ repositoryId }),
    enabled: !!repositoryId
  });
  return { repositoryInfo: data?.data, isLoading, refetch };
};
export const useGetDependencyGraph = ({ repoId }: { repoId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [`${API_ENPOINTS.REPOSITORIES}${repoId}/dependency_graph`],
    queryFn: () => repositoryApi.getDependencyGraph(repoId),
    enabled: !!repoId
  });
  return { graphData: data?.data?.graph, isLoading };
};
