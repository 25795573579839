import { useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, reverseApi } from "@api";
import {
  ReverseRepositoryInput,
  ReverseFileInput,
  ReverseListInput,
  ReverseFileResponse,
  ReverseCopybookResponse,
  ReverseCobolResponse
} from "@types";

export const useGetReverseFile = (params: ReverseRepositoryInput) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.REVERSE_REPOSITORY, params],
    queryFn: () => reverseApi.getReverseFile(params),
    enabled: !!params.repoId
  });
  return { reverseFile: data?.data, isLoading };
};

export const useGetReverseByPath = (params: ReverseFileInput) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.REVERSE_REPOSITORY, "byPath", params],
    queryFn: () => reverseApi.getReverseByPath(params),
    enabled: !!(params.repoId && params.type && params.name),
    refetchOnWindowFocus: false,
    select: response => {
      // Type guard functions
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const isCopybook = (data: ReverseFileResponse): data is ReverseCopybookResponse => {
        return params.type === "COPY";
      };

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const isCobol = (data: ReverseFileResponse): data is ReverseCobolResponse => {
        return params.type === "COBOL";
      };

      // Transform data based on type
      if (isCopybook(response)) {
        return {
          type: "COPY" as const,
          data: response
        };
      }

      if (isCobol(response)) {
        return {
          type: "COBOL" as const,
          data: response
        };
      }

      // Default case with type assertion
      return {
        type: "DEFAULT" as const,
        data: response as ReverseFileResponse
      };
    }
  });

  return {
    reverseFile: data?.data,
    fileType: data?.type,
    isLoading
  };
};

export const useListReverses = (params: ReverseListInput) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.REVERSE_REPOSITORY, "list", params],
    queryFn: () => reverseApi.listReverses(params),
    enabled: !!params.repoId,
    refetchOnWindowFocus: false
  });

  return {
    files: data?.data || [],
    isLoading
  };
};
