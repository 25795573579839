import { ComplexityRepositoryResponse } from "@types";

import { API_ENPOINTS } from "./endpoints";
import { HttpClient } from "./httpClient";

export default class ComplexityApi {
  static getComplexitiesByRepository = async (repoId: string) => {
    return HttpClient.get<ComplexityRepositoryResponse>(
      `${API_ENPOINTS.COMPLEXITIES_REPOSITORY}${repoId}`
    );
  };
}
