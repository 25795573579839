export type KeyWorkSpace = keyof typeof workspace;
export type KeyExploration = keyof typeof exploration;
export type KeyMigration = keyof typeof migration;
// > Workspace
export const workspace = {
  WORKSPACE: "/:workspaceCategory?",
  WORKSPACE_HOME: "/",
  WORKSPACE_REPOSITORY: "/repository",
  WORKSPACE_REPOSITORIES: "/repositories",
  WORKSPACE_EXPLORATION: "/exploration",
  WORKSPACE_MIGRATION_REPOSITORY: "/migration"
};

export const workspaceCategories: Record<
  keyof Pick<
    typeof workspace,
    | "WORKSPACE_REPOSITORY"
    | "WORKSPACE_EXPLORATION"
    | "WORKSPACE_MIGRATION_REPOSITORY"
    | "WORKSPACE_REPOSITORIES"
  >,
  string
> = {
  WORKSPACE_REPOSITORY: workspace.WORKSPACE_REPOSITORY.replace("/", ""),
  WORKSPACE_REPOSITORIES: workspace.WORKSPACE_REPOSITORIES.replace("/", ""),
  WORKSPACE_EXPLORATION: workspace.WORKSPACE_EXPLORATION.replace("/", ""),
  WORKSPACE_MIGRATION_REPOSITORY: workspace.WORKSPACE_MIGRATION_REPOSITORY.replace("/", "")
};

export const workspaceRouters = {} as Record<KeyWorkSpace, string>;
Object.keys(workspace).forEach(
  key => (workspaceRouters[key as KeyWorkSpace] = `${workspace[key as KeyWorkSpace]}`)
);

// > Exploration
export const exploration = {
  EXPLORATION: "/:explorationCategory?/*",
  EXPLORATION_HOME: "/",
  EXPLORATION_GRAPH: "/graph",
  EXPLORATION_REVERSE: "/reverse",
  EXPLORATION_ENTRYPOINTS: "/entrypoints",
  EXPLORATION_REPORTS: "/reports",
  EXPLORATION_REPORTS_DETAIL: "/reports/detail/:type/:name",
  EXPLORATION_PROGRAMS_REPORTS: "/programs-reports",
  EXPLORATION_PROGRAMS_REPORTS_DETAIL: "/programs-reports/detail/:type/:name",
  EXPLORATION_FILE: "/file",
  EXPLORATION_ASSESSMENT: "/assessment",
  EXPLORATION_ASSETS: "/assets",
  EXPLORATION_INVENTORY: "/inventory",
  EXPLORATION_COMPLEXITY: "/complexity",
  EXPLORATION_DATA: "/data",
  EXPLORATION_FILE_WITH_ID: "/file/:fileId",
  EXPLORATION_WITH_REPOSITORY_ID: "/exploration/:repoId",
  EXPLORATION_OVERVIEW: "/overview",
  EXPLORATION_FILE_WITH_ID_CATEGORY: "/file/:fileId/:fileCategory?"
};

export const migration = {
  MIGRATION: "/:migrationCategory?/*",
  MIGRATION_HOME: "/",
  MIGRATION_GRAPH: "/graph",
  MIGRATION_FILE: "/file",
  MIGRATION_ASSESSMENT: "/assessment",
  MIGRATION_FILE_WITH_ID: "/file/:fileId",
  MIGRATION_FILE_WITH_ID_CATEGORY: "/file/:fileId/:fileCategory?",
  MIGRATION_FILE_MATCHING: "/file/:fileId/matching"
};

export const explorationCategories: Record<
  keyof Pick<
    typeof exploration,
    | "EXPLORATION_GRAPH"
    | "EXPLORATION_FILE"
    | "EXPLORATION_ASSESSMENT"
    | "EXPLORATION_OVERVIEW"
    | "EXPLORATION_ASSETS"
    | "EXPLORATION_INVENTORY"
    | "EXPLORATION_COMPLEXITY"
    | "EXPLORATION_DATA"
    | "EXPLORATION_REVERSE"
    | "EXPLORATION_ENTRYPOINTS"
    | "EXPLORATION_REPORTS"
    | "EXPLORATION_REPORTS_DETAIL"
    | "EXPLORATION_PROGRAMS_REPORTS"
    | "EXPLORATION_PROGRAMS_REPORTS_DETAIL"
  >,
  string
> = {
  EXPLORATION_GRAPH: exploration.EXPLORATION_GRAPH.replace("/", ""),
  EXPLORATION_FILE: exploration.EXPLORATION_FILE.replace("/", ""),
  EXPLORATION_ASSESSMENT: exploration.EXPLORATION_ASSESSMENT.replace("/", ""),
  EXPLORATION_OVERVIEW: exploration.EXPLORATION_OVERVIEW.replace("/", ""),
  EXPLORATION_ASSETS: exploration.EXPLORATION_ASSETS.replace("/", ""),
  EXPLORATION_INVENTORY: exploration.EXPLORATION_INVENTORY.replace("/", ""),
  EXPLORATION_COMPLEXITY: exploration.EXPLORATION_COMPLEXITY.replace("/", ""),
  EXPLORATION_DATA: exploration.EXPLORATION_DATA.replace("/", ""),
  EXPLORATION_REVERSE: exploration.EXPLORATION_REVERSE.replace("/", ""),
  EXPLORATION_ENTRYPOINTS: exploration.EXPLORATION_ENTRYPOINTS.replace("/", ""),
  EXPLORATION_REPORTS: exploration.EXPLORATION_REPORTS.replace("/", ""),
  EXPLORATION_REPORTS_DETAIL: exploration.EXPLORATION_REPORTS_DETAIL.replace("/", ""),
  EXPLORATION_PROGRAMS_REPORTS: exploration.EXPLORATION_PROGRAMS_REPORTS.replace("/", ""),
  EXPLORATION_PROGRAMS_REPORTS_DETAIL: exploration.EXPLORATION_PROGRAMS_REPORTS_DETAIL.replace(
    "/",
    ""
  )
};

export const explorationRouters = {} as Record<KeyExploration, string>;
Object.keys(exploration).forEach(
  key => (explorationRouters[key as KeyExploration] = `${exploration[key as KeyExploration]}`)
);

export const migrationRouters = {} as Record<KeyMigration, string>;

Object.keys(migration).forEach(
  key =>
    (migrationRouters[key as KeyMigration] = `${workspace.WORKSPACE_MIGRATION_REPOSITORY}${
      migration[key as KeyMigration]
    }`)
);

const mainRouters = {
  LOGIN: "/signin",
  REGISTER: "/register",
  VERIFY: "/verify",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/resetpwd",
  UNAUTHORIZED: "/401",
  NOT_FOUND: "/404",
  SERVER_ERROR: "/500",
  CATCH_ALL_ROUTE: "*",
  UNDER_MAINTENANCE: "/under-maintenance",
  PRIVACY_POLICY: "/privacy-and-policy",
  COMMING_SOON: "/coming-soon",
  THEME: "/theme",
  SHARE: "/share/:projectId",
  SHARE_MIGRATION: "/share/:projectId?matchingId=:matchingId"
};

export const ROUTERS = {
  ...mainRouters,
  ...workspaceRouters,
  ...explorationRouters,
  ...migrationRouters,
  ...explorationCategories
};

export const ROUTERS_GUARD = [ROUTERS.WORKSPACE, ROUTERS.EXPLORATION];
