/* eslint-disable no-console */
import React from "react";
import { Button } from "antd";

import { Flex, Typography } from "@components";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(error);
    console.log(errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Flex
          direction='column'
          style={{
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh"
          }}
        >
          <Typography level='h2' color={"primary1"} style={{ margin: 50 }}>
            Something went wrong!!!
          </Typography>
          <Flex gap={8}>
            <Button
              type='primary'
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Back Home
            </Button>
            <Button type='primary' onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </Flex>
        </Flex>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
