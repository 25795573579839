export { ReactComponent as LogoNameSvg } from "./logo-name.svg";
export { ReactComponent as LogoSvg } from "./logo.svg";
export { ReactComponent as LogoFullSvg } from "./logo-full.svg";
export { ReactComponent as SearchSvg } from "./search.svg";
export { ReactComponent as CorporateSvg } from "./corporate.svg";
export { ReactComponent as OrganizationSvg } from "./organization.svg";
export { ReactComponent as FrameCoverSvg } from "./frame-cover.svg";
export { ReactComponent as ShieldSvg } from "./shield.svg";
export { ReactComponent as EditSvg } from "./edit.svg";
export { ReactComponent as LogOutSvg } from "./log-out.svg";
export { ReactComponent as MigrationSvg } from "./migration.svg";
export { ReactComponent as OverviewSvg } from "./overview.svg";
export { ReactComponent as PlusSvg } from "./plus.svg";
export { ReactComponent as RepositorySvg } from "./repository.svg";
export { ReactComponent as CobolListSvg } from "./cobol-list.svg";
export { ReactComponent as SettingsSvg } from "./settings.svg";
export { ReactComponent as ArrowRightSvg } from "./arrow-right.svg";
export { ReactComponent as ArrowLeftSvg } from "./arrow-left.svg";
export { ReactComponent as ArrowUpSvg } from "./arrow-up.svg";
export { ReactComponent as ArrowDownSvg } from "./arrow-down.svg";
export { ReactComponent as FolderSvg } from "./folder.svg";
export { ReactComponent as RootSvg } from "./root.svg";
export { ReactComponent as DividerSvg } from "./divider.svg";
export { ReactComponent as MenuSvg } from "./menu.svg";
export { ReactComponent as ShareSvg } from "./share.svg";
export { ReactComponent as ArrowHighPrioritySvg } from "./arrow-hight-priority.svg";
export { ReactComponent as ArrowLowPrioritySvg } from "./arrow-low-priority.svg";
export { ReactComponent as ArrowMediumPrioritySvg } from "./arrow-medium-priority.svg";
export { ReactComponent as GaugeBaseSvg } from "./gauge-base.svg";
export { ReactComponent as ArrowGaugeSvg } from "./gauge-arrow.svg";
export { ReactComponent as ZoomInSvg } from "./zoom-in.svg";
export { ReactComponent as ApplicationInSvg } from "./application.svg";
export { ReactComponent as DatabaseInSvg } from "./database.svg";
export { ReactComponent as ChainInSvg } from "./chain.svg";
export { ReactComponent as ExcludeInSvg } from "./exclude.svg";
export { ReactComponent as SysAssessmentSvg } from "./sys-assessment.svg";
export { ReactComponent as SysExplorationSvg } from "./sys-exploration.svg";
export { ReactComponent as BellSvg } from "./bell.svg";
export { ReactComponent as CodeSvg } from "./code.svg";
export { ReactComponent as DotSvg } from "./dot.svg";
export { ReactComponent as CheckBoxSvg } from "./checkbox.svg";
export { ReactComponent as CheckCheckBoxSvg } from "./check-checkbox.svg";
export { ReactComponent as CirclePlusSvg } from "./circle-plus.svg";
export { ReactComponent as FileSvg } from "./file.svg";
export { ReactComponent as GitSvg } from "./git.svg";
export { ReactComponent as BigFolderSvg } from "./big-folder.svg";
export { ReactComponent as CloseSvg } from "./close.svg";
export { ReactComponent as ChatSvg } from "./chat.svg";
export { ReactComponent as BigChatSvg } from "./big-chat.svg";
export { ReactComponent as SendInSvg } from "./send.svg";
export { ReactComponent as MachineInSvg } from "./machine.svg";
export { ReactComponent as ClientInSvg } from "./client.svg";
export { ReactComponent as GraphInSvg } from "./graph.svg";
export { ReactComponent as DoubleChevronInSvg } from "./double-chevron.svg";
export { ReactComponent as ChevronInSvg } from "./chevron.svg";
export { ReactComponent as AIInSvg } from "./ai.svg";
export { ReactComponent as InfoInSvg } from "./info.svg";
export { ReactComponent as GitBranchInSvg } from "./git-branch.svg";
export { ReactComponent as EyeSvg } from "./eye.svg";
export { ReactComponent as PolygonSvg } from "./polygon.svg";
export { ReactComponent as RectangleSvg } from "./rectangle.svg";
export { ReactComponent as EllipseSvg } from "./ellipse.svg";
export { ReactComponent as DiamondSvg } from "./diamond.svg";
export { ReactComponent as PlusSquareSvg } from "./plus-square.svg";
export { ReactComponent as TrashSvg } from "./trash.svg";
export { ReactComponent as OctagonSvg } from "./octagon.svg";
export { ReactComponent as StarSvg } from "./star.svg";
export { ReactComponent as ParallelogramSvg } from "./parallelogram.svg";
export { ReactComponent as CylinderSvg } from "./cylinder.svg";
export { ReactComponent as CylinderGreenSvg } from "./cylinder-green.svg";
