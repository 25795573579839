export default class DataAssetApi {
  static getDataAsset = async () => {
    return [
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS=aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure:
          "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      },
      {
        dataset_name: "FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        variable_name: "ACCTDATA",
        jcl_path: "",
        dd_statement: "DSN=FPTLT01.DEMO.CARDDEMO.ACCTDATA.PS",
        program_id: "IDCAMS",
        program_path: "",
        access_mode: "",
        copybook: "",
        copybook_path: "",
        data_structure: ""
      }
    ];
  };
}
