import { useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, complexityApi } from "@api";

export const useGetComplexitiesByRepository = ({
  repoId,
  enabled = true
}: {
  repoId: string;
  enabled?: boolean;
}) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.COMPLEXITIES_REPOSITORY, repoId],
    queryFn: () => complexityApi.getComplexitiesByRepository(repoId),
    enabled: !!repoId && enabled
  });
  return {
    complexitiesByRepository: data?.data.complexities ?? [],
    predictComplexity: data?.data.complexities?.[0]?.result?.complexity || [],
    isLoading
  };
};
