/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Layout } from "antd";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Flex, Typography } from "@components";
import { allColors } from "@style";
import { HEADER_DASHBOARD_HEIGHT } from "@layouts/TopbarLayout/config";
import { CloseSvg } from "@assets/svg";
import { AssesmentResult, DependencyGraph, Edge, EntryPoint, FileGroup } from "@types";
import { useGetAssessmentRepository, useGetDependencyGraph } from "@services";

import { Node as NodeGraph } from "./entrypoints/fake/graph";

const { Header } = Layout;

// type EntryPoint = { value: string; type: "bms" | "jcl"; id: string };

type EdgeComputed = { source: string; target: string; id: string };
const getConnectedNodesAndEdges = (
  entryId: string,
  totalGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  }
): {
  nodes: Array<{
    _id: string;
    data: { label: string };
    fileType: FileGroup;
    loc?: number | null;
    complexity?: number | null;
    name: string;
  }>;
  edges: Array<{
    id: string;
    source: string;
    target: string;
  }>;
} => {
  const visitedNodes = new Set<string>();
  const visitedEdges: EdgeComputed[] = [];

  const traverse = (nodeId: string) => {
    visitedNodes.add(nodeId);

    // Find edges where the source is the current nodeId
    totalGraph.edges.forEach(edge => {
      if (edge.source === nodeId && !visitedNodes.has(edge.target)) {
        visitedEdges.push(edge);
        traverse(edge.target); // Recursively traverse the child node
      }
    });
  };

  traverse(entryId);

  // Filter the nodes that were visited
  const nodes = totalGraph.nodes.filter(node => visitedNodes.has(node._id));

  return { nodes, edges: visitedEdges };
};
type EntryPointComputed = { value: string; type: string; id: string };

type EntryPointValue = {
  entries: EntryPointComputed[];
  // setEntries: Dispatch<SetStateAction<EntryPoint[]>>;
  selectedEntries: Record<string, any>;
  setSelectedEntries: Dispatch<SetStateAction<Record<string, any>>>;
  totalGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  } | null;
  selectedGraph: {
    nodes: Array<{
      _id: string;
      data: { label: string };
      fileType: FileGroup;
      loc?: number | null;
      complexity?: number | null;
      name: string;
    }>;
    edges: Array<{
      id: string;
      source: string;
      target: string;
    }>;
  };
  selectedEntry: string | null;
  setSelectedEntry: Dispatch<SetStateAction<string | null>>;
  showNode: Record<string, boolean>;
  setShowNode: Dispatch<SetStateAction<Record<string, boolean>>>;
  select: "jcl" | "bms" | null;
  setSelect: Dispatch<SetStateAction<"jcl" | "bms" | null>>;
  graphData: DependencyGraph | null;
  inventoryData: AssesmentResult | null;
  loadingGraphData: boolean;
  selectedGroup: string[];
  setSelectedGroup: Dispatch<SetStateAction<string[]>>;
};

const EntryPointContext = createContext<EntryPointValue>(null as unknown as EntryPointValue);

export const useEntryPoint = () => {
  return useContext(EntryPointContext);
};

export const ExplorationContent = ({
  recentRoutes,
  setRecentRoutes
}: {
  recentRoutes: Record<string, string>;
  setRecentRoutes: Dispatch<SetStateAction<Record<string, string>>>;
}) => {
  const { repoId } = useParams();
  const [selectedEntries, setSelectedEntries] = useState<Record<string, any>>({});
  const [showNode, setShowNode] = useState<Record<string, boolean>>({});
  const [selectedEntry, setSelectedEntry] = useState<string | null>(null);
  const [select, setSelect] = useState<"jcl" | "bms" | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
  const navigate = useNavigate();
  const { pathname, search } = window.location;
  const path = `${pathname}${search}`;

  const { graphData, isLoading: loadingGraph } = useGetDependencyGraph({
    repoId: repoId as string
  });
  const { inventoryData, isLoading: loadingAssetment } = useGetAssessmentRepository({
    repoId: repoId as string
  });

  const entries = useMemo(
    () =>
      graphData?.entry_points.map((item: EntryPoint) => ({
        value: item.name,
        type: item.label === "BMS" ? "bms" : "jcl",
        id: item._id
      })),
    [graphData?.entry_points, repoId]
  );
  const totalGraph = useMemo(
    () => ({
      edges:
        graphData?.edges.map(({ target, source }: Edge) => ({
          id: `${source}-${target}`,
          source,
          target
        })) ?? [],
      nodes:
        graphData?.nodes.map(({ _id, label, name, loc, complexity }: NodeGraph) => ({
          _id,
          data: { label: name },
          fileType: label,
          loc,
          complexity,
          name
        })) ?? []
    }),
    [graphData, repoId]
  );
  // const nodes = useMemo(
  //   () =>
  //     graphData?.nodes.map(({ _id, label, name, loc, complexity }: NodeGraph) => ({
  //       _id,
  //       data: { label: name },
  //       fileType: label,
  //       loc,
  //       complexity,
  //       name
  //     })),
  //   [graphData?.nodes, repoId]
  // );
  const selectedId = useMemo(() => selectedEntry?.split("-").at(0) ?? "", [selectedEntry]);

  const selectedGraph = useMemo(() => {
    if (!selectedId || !totalGraph) return { nodes: [], edges: [] };
    return getConnectedNodesAndEdges(selectedId, totalGraph as any);
  }, [selectedId, totalGraph]);

  useEffect(() => {
    const targetNodes = new Set(selectedGraph.edges.map(({ target }) => target));
    const parentNodes = selectedGraph.nodes
      .map(({ _id }) => _id)
      .filter(id => !targetNodes.has(id));
    setShowNode(
      selectedGraph.nodes.reduce<Record<string, boolean>>((result, { _id }) => {
        const next = { ...result };
        next[_id] = parentNodes.includes(_id);
        return next;
      }, {})
    );
  }, [selectedGraph.edges, selectedGraph.nodes]);

  // useEffect(() => {
  //   if (!selectedGraph?.nodes || !selectedGraph?.edges) return;
  //   const targetNodes = new Set(selectedGraph.edges.map(({ target }) => target));
  //   const parentNodes = selectedGraph.nodes
  //     .map(({ _id }) => _id)
  //     .filter(id => !targetNodes.has(id));

  //   const newShowNode: Record<string, boolean> = selectedGraph.nodes.reduce(
  //     (result: any, { _id }) => {
  //       result[_id] = parentNodes.includes(_id);
  //       return result;
  //     },
  //     {}
  //   );
  //   setShowNode(prev => {
  //     if (JSON.stringify(prev) !== JSON.stringify(newShowNode)) {
  //       return newShowNode;
  //     }
  //     return prev;
  //   });
  // }, [selectedGraph.edges, selectedGraph.nodes]);

  // eslint-disable-next-line no-console

  useEffect(() => {
    if (!entries) return;
    const firstEntry = entries?.[0];
    if (firstEntry) {
      setSelectedEntry(`${firstEntry.id}-${firstEntry.value}`);
    }
  }, [entries]);

  return (
    <EntryPointContext.Provider
      value={{
        graphData: graphData!,
        loadingGraphData: loadingGraph || loadingAssetment,
        entries: entries!,
        selectedEntries,
        setSelectedEntries,
        totalGraph: totalGraph,
        selectedGraph,
        selectedEntry,
        setSelectedEntry,
        showNode,
        setShowNode,
        select,
        setSelect,
        inventoryData: inventoryData!,
        selectedGroup,
        setSelectedGroup
      }}
    >
      <Header
        id='main-header'
        style={{
          height: HEADER_DASHBOARD_HEIGHT,
          borderBottom: `1px solid ${allColors.neutral6}`,
          background: allColors.neutral4,
          padding: "0 16px"
        }}
      >
        <Flex
          style={{ width: "100%", height: "100%", overflow: "auto", padding: "0 16px " }}
          gap={16}
          justify='flex-start'
          align='center'
        >
          {Object.entries(recentRoutes).map(([key, value]) => (
            <Flex
              gap={8}
              style={{
                borderRadius: "4px",
                background: path === value ? allColors.neutral2 : allColors.neutral1,
                padding: "8px",
                cursor: "pointer",
                minWidth: "max-content"
              }}
              onClick={() => navigate(value)}
              key={key}
              center
            >
              <Typography
                level='caption-12m'
                color={path === value ? "primary10" : "neutral6"}
                style={{ whiteSpace: "nowrap" }}
              >
                {key}
              </Typography>
              <CloseSvg
                stroke={path === value ? allColors.primary10 : allColors.neutral6}
                onClick={e => {
                  e.stopPropagation();
                  if (Object.keys(recentRoutes).length === 1) return;
                  const routes = { ...recentRoutes };

                  delete routes[key];

                  setRecentRoutes(routes);

                  if (path === value) navigate(Object.values(routes)[0]);
                }}
              />
            </Flex>
          ))}
        </Flex>
      </Header>
      <Layout>
        <Flex style={{ background: allColors.neutral4, flex: "1 1 0%", height: "100%" }}>
          <Outlet />
        </Flex>
      </Layout>
    </EntryPointContext.Provider>
  );
};
