import { useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, projectApi } from "@api";
import { ProjectApiResponse } from "@types";

export const useGetProjects = () => {
  const { data, isLoading } = useQuery<ProjectApiResponse, Error>({
    queryKey: [API_ENPOINTS.PROJECTS],
    queryFn: projectApi.getProjectList
  });

  return { project: data?.data?.projects?.[0], isLoading };
};
