import { useMutation, useQuery } from "@tanstack/react-query";

import { API_ENPOINTS, assessmentApi } from "@api";

export const useTriggerAssessment = () =>
  useMutation({
    mutationFn: assessmentApi.triggerAssessment
  });

export const useGetAssessmentRepository = ({ repoId }: { repoId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [API_ENPOINTS.ASSESSMENTS_REPOSITORY, repoId],
    queryFn: () => assessmentApi.getAssessmentRepository(repoId),
    enabled: !!repoId
  });
  return {
    assessmentRepository: data?.data.assessments ?? [],
    inventoryData: data?.data.assessments?.[0]?.result,
    isLoading
  };
};
