export const API_ENPOINTS = {
  LOGIN: "/auth/token",
  PROJECTS: "/projects/",
  CHAT_PROJECTS: "/chat/project",
  ASSESSMENTS: "/assessments/",
  ASSESSMENTS_REPOSITORY: "/assessments/repository/",
  COMPLEXITIES_REPOSITORY: "/complexities/repository/",
  REPOSITORIES: "/repositories/",
  REPOSITORIES_GRAPHS: "/repositories/graphs",
  REPOSITORIES_UPLOAD: "/repositories/upload",
  REVERSE_REPOSITORY: "/reverse/repository"
};
