import { removeAuthToken } from "@utils";
import { appActions, store, userActions } from "@store";
import { AuthTokens } from "@types";

import { API_ENPOINTS } from "./endpoints";
import { HttpClient } from "./httpClient";

export default class UserApi {
  static doLoginRequest = (params: FormData) => {
    return HttpClient.post<AuthTokens>(API_ENPOINTS.LOGIN, params, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  };

  static cleanStore = () => {
    store.dispatch(userActions.clean());
    store.dispatch(appActions.clean());
  };

  static doLogoutRequest = async () => {
    removeAuthToken();
    this.cleanStore();
  };
}
